import { Dispatch } from "react";
import { MailDataRequired } from "@sendgrid/helpers/classes/mail";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { PersonalizationData } from "@sendgrid/helpers/classes/personalization";

import { Client, CologicCustomer, QuoteProduct } from "./RootComponent.d";
import { Merchant } from "./QuoteCreate.d";
import { AppStateAction } from "./RootComponent.d";

import firebaseConfig from "./firebaseProdConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Config = {
    BACKEND_URL: "https://procurement-backend-tk2o5fazwq-nw.a.run.app",
//    BACKEND_URL: "http://localhost:8080",
    VERSION: "v0.5.0",
    ZOKO_URL: "https://chat.zoko.io/v2",
    QUOTES_URL: "https://quotes.cologic.co",
    GTM_ID: "GTM-54RK2JD",
    GTM_AUTH: "a5DUTt6okIbqV5OmZewEZA"
};

async function postPayload(url: string, payload: MailDataRequired) {
    const fetchOpts = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            accept: "application/json"
        },
        body: JSON.stringify(payload)
    };
    const res = await fetch(url, fetchOpts);
    if (!res.ok) {
        const data = await res.json();
        throw new Error(`Sendgrid error: ${JSON.stringify(data)}`);
    }
    return res.blob();
}

async function createEmailPayload(
    customer: CologicCustomer,
    mailto: Client | Merchant[],
    products: QuoteProduct[],
    quoteId?: string
) {
    let personalizations: PersonalizationData[];
    let templateId: string;
    if (mailto instanceof Array) {
        personalizations = mailto.map(merchant => ({
            to: [{ email: merchant.email }],
            cc: [{ email: customer.email, name: customer.companyName }],
            dynamicTemplateData: {
                recipient: merchant.contactName,
                senderName: customer.name,
                senderCompany: customer.companyName,
                products
            }
        }));
        templateId = "d-9ce02e12e828406380c5760e3aeb7a5b";
    } else {
        const subTotal = products.reduce(
            (sum, p) => sum + Number(p.price) * Number(p.quantity),
            0
        );
        const vat = subTotal * 0.2; // TODO Calculate correctly
        const localeOpts = { currency: "GBP", style: "currency" };
        const productsToLocale = products.map(p => ({
            ...p,
            price: Number(p.price).toLocaleString("en-GB", localeOpts),
            amount: (Number(p.price) * Number(p.quantity)).toLocaleString(
                "en-GB",
                localeOpts
            )
        }));
        const terms =
            customer.terms || "Terms and conditions available upon request";
        personalizations = [
            {
                to: [{ email: mailto.email }],
                cc: [{ email: customer.email, name: customer.companyName }],
                dynamicTemplateData: {
                    client: {
                        name: mailto.name,
                        addressLine1: mailto.addressLine1,
                        city: mailto.city,
                        postalCode: mailto.postalCode
                    },
                    sender: {
                        name: customer.name,
                        companyName: customer.companyName,
                        addressLine1: customer.addressLine1,
                        city: customer.city,
                        postalCode: customer.postalCode,
                        phone: `0${customer.phone.slice(2)}`,
                        vatRegNo: customer.vatRegNo
                    },
                    issueDate: new Date().toLocaleDateString("en-GB"),
                    subTotal: subTotal.toLocaleString("en-GB", localeOpts),
                    vat: vat.toLocaleString("en-GB", localeOpts),
                    total: (subTotal + vat).toLocaleString("en-GB", localeOpts),
                    quoteId,
                    products: productsToLocale,
                    companyLogo: customer.companyLogos
                        ? customer.companyLogos[0].downloadURL
                        : undefined,
                    terms
                }
            }
        ];
        templateId = "d-d14b126470ec4e3f97595bab8e4ef540";
    }
    return {
        templateId,
        from: {
            email: `${customer.id}@cologic.co`,
            name: customer.companyName
        },
        replyTo: { email: customer.email, name: customer.companyName },
        personalizations
    } as MailDataRequired;
}

function displaySuccess(dispatch: Dispatch<AppStateAction>, message?: string) {
    message = message || "Success!";
    dispatch({ type: "setAndShowSuccess", value: message });
    setTimeout(() => dispatch({ type: "setShowSuccess", value: false }), 3000);
}

function displayError(dispatch: Dispatch<AppStateAction>, message?: string) {
    message = message || "Error!";
    dispatch({ type: "setAndShowError", value: message });
    setTimeout(() => dispatch({ type: "setShowError", value: false }), 3000);
}

export {
    createEmailPayload,
    Config,
    displayError,
    displaySuccess,
    postPayload,
    db
};
