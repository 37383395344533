const firebaseConfig = {
  "apiKey": "AIzaSyBxAHPUTNhoJ8KjPwRkIVMsnUdwb8t5l-o",
  "authDomain": "dev-mobile-app-prod.firebaseapp.com",
  "databaseURL": "https://dev-mobile-app-prod-default-rtdb.europe-west1.firebasedatabase.app",
  "projectId": "dev-mobile-app-prod",
  "storageBucket": "dev-mobile-app-prod.appspot.com",
  "messagingSenderId": "393558891839",
  "appId": "1:393558891839:web:93180903b38ade152e878a",
  "measurementId": "G-6NP2504NHY"
};

export default firebaseConfig;
