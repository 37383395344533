import { createContext, useReducer } from "react";
import { Outlet } from "react-router-dom";

import { AppStateAction, AppState } from "./RootComponent.d";
import { AlertsProvider } from "./providers";

const EMPTY_APP_STATE = {
    form: {
        name: "",
        addressLine1: "",
        city: "",
        postalCode: "",
        email: "",
        phone: "",
        mainPrice: "",
        mainDescription: "",
        optionalPrice: "",
        optionalDescription: "",
        products: [{ name: "", quantity: "", price: "", description: "" }]
    },
    showSuccess: false,
    showError: false,
    errorMsg: ""
} as AppState;

const AppStateContext = createContext({
    appState: { ...EMPTY_APP_STATE } as AppState,
    dispatch: (_: AppStateAction) => {}
});

function RootComponent() {
    const appStateReducer = (oldState: AppState, action: AppStateAction) => {
        let newState = { ...oldState } as AppState;
        switch (action.type) {
            case "replaceFormState":
                newState.form = action.value;
                break;
            case "setFormField":
                newState.form[action.field] = action.value;
                break;
            case "writeFormProduct":
                if (action.field !== "amount") {
                    newState.form.products[action.index][action.field] =
                        action.value;
                } else {
                    newState.form.products[action.index][action.field] = Number(
                        action.value
                    );
                }
                break;
            case "pushFormProduct":
                newState.form.products = newState.form.products.concat({
                    name: "",
                    quantity: "",
                    price: "",
                    description: ""
                });
                break;
            case "deleteFormProduct":
                newState.form.products.splice(action.position, 1);
                break;
            case "resetFormProducts":
                newState.form.products = [
                    {
                        name: "",
                        quantity: "",
                        price: "",
                        description: ""
                    }
                ];
                break;
            case "resetForm":
                newState.form = { ...EMPTY_APP_STATE.form };
                break;
            case "setAndShowSuccess":
                newState.successMsg = action.value || "Success!";
                newState.showSuccess = true;
                break;
            case "setAndShowError":
                newState.errorMsg = action.value || "Error!";
                newState.showError = true;
                break;
            case "setShowSuccess":
                newState.showSuccess = action.value;
                break;
            case "setShowError":
                newState.showError = action.value;
                break;
        }
        return newState;
    };
    const [appState, dispatch] = useReducer(appStateReducer, {
        ...EMPTY_APP_STATE
    } as AppState);

    return (
        <AppStateContext.Provider value={{ appState, dispatch }}>
            <AlertsProvider>
                <Outlet />
            </AlertsProvider>
        </AppStateContext.Provider>
    );
}

export { RootComponent, AppStateContext };
