import Carousel from "react-bootstrap/Carousel";

function ProductsCarousel() {
    return (
        <Carousel controls={false} indicators={false} interval={4000}>
            {[...new Array(4)].map((_, i) => (
                <Carousel.Item key={i}>
                    <img
                        className="d-block mx-auto"
                        src={`img/slide-${i}.png`}
                        alt={`Slide ${i}`}
                        style={{ maxHeight: "300px" }}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default ProductsCarousel;
