import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TagManager from "react-gtm-module";

import "./index.css";
import QuoteCreate from "./QuoteCreate";
import { QuoteEdit, loader as quoteLoader } from "./QuoteEdit";
import ErrorPage from "./ErrorPage";
import { RootComponent } from "./RootComponent";
import { loader as productSearchLoader, ProductSearch } from "./ProductSearch";
import { Config } from "./utils";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                path="/"
                element={<RootComponent />}
                errorElement={<ErrorPage />}
            >
                <Route
                    index
                    element={<ProductSearch />}
                    loader={productSearchLoader}
                />
                <Route path="create-quote" element={<QuoteCreate />} />
                <Route
                    path="customers/:customerId/quotes/:quoteId"
                    element={<QuoteEdit />}
                    loader={quoteLoader}
                />
                <Route
                    path="product-search"
                    element={<ProductSearch />}
                    loader={productSearchLoader}
                />
            </Route>
        </>
    )
);

TagManager.initialize({
    gtmId: Config.GTM_ID,
    preview: "env-1",
    auth: Config.GTM_AUTH
});
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
