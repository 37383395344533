import { MerchantsProps, MerchantTickboxProps } from "./Merchants.d";

function MerchantTickbox(props: MerchantTickboxProps): JSX.Element {
    const checkBoxId = `checkbox-${props.index}`;
    return (
        <div>
            <input
                type="checkbox"
                id={checkBoxId}
                name="merchants"
                checked={props.checked}
                onChange={() => props.onChange(props.index)}
            />
            <label htmlFor={checkBoxId}>{props.name}</label>
        </div>
    );
}

export default function Merchants(props: MerchantsProps): JSX.Element | null {
    if (props.hidden) {
        return null;
    }
    return (
        <fieldset className="merchants-fieldset">
            <legend>Choose which merchants the email should go to</legend>
            {props.data.map((m, i) => (
                <MerchantTickbox
                    index={i}
                    key={i}
                    name={m.name}
                    checked={props.checkedList[i]}
                    onChange={props.onChange}
                />
            ))}
        </fieldset>
    );
}
