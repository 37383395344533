import { FormEvent, useContext, useEffect, useState } from "react";
import {
    collection,
    doc,
    DocumentReference,
    getDoc,
    getDocs,
    query,
    Query,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import { MailDataRequired } from "@sendgrid/helpers/classes/mail";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import {
    Config,
    displayError,
    displaySuccess,
    createEmailPayload,
    db,
    postPayload
} from "./utils";
import { CologicCustomer } from "./RootComponent.d";
import { AppStateContext } from "./RootComponent";
import { ApiKeys, Merchant, ZokoCustomer } from "./QuoteCreate.d";
import Merchants from "./Merchants";
import Products from "./Products";
import QuoteForm from "./QuoteForm";
import "./QuoteCreate.css";

function QuoteCreate() {
    const { appState, dispatch } = useContext(AppStateContext);

    //=============================== PROCESSING ================================

    const [loading, setLoading] = useState(true);
    const [zokoCustomers, setZokoCustomers] = useState([] as ZokoCustomer[]);
    const [zokoApiKey, setZokoApiKey] = useState("");
    const [merchants, setMerchants] = useState([] as Merchant[]);
    const [merchantSelection, setMerchantSelection] = useState([] as boolean[]);
    const [customerId, setCustomerId] = useState("");
    const [operation, setOperation] = useState("clientQuote");
    const [customer, setCustomer] = useState({} as CologicCustomer);

    const [formValidated, setFormValidated] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [displayMerchants, setDisplayMerchants] = useState(false);
    const [loadingCustomerData, setLoadingCustomerData] = useState(false);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const keysSnap = await getDoc(
                doc(db, "apiAccess", "keys") as DocumentReference<ApiKeys>
            );
            if (!keysSnap.exists()) {
                alert("Error fetching API keys");
                return;
            }
            const apiKeys = keysSnap.data();

            // Zoko Customers
            let zokoCustomers: ZokoCustomer[] = [];
            let zokoOpts = {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    apikey: apiKeys.zokoApiKey
                }
            };
            let p = 1;
            let page: { totalPages: number; customers: ZokoCustomer[] };
            do {
                const zokoRes: Response = await fetch(
                    `${Config.ZOKO_URL}/customer?channel=whatsapp&page=${p}&` +
                        `includeAssign=true`,
                    zokoOpts
                );
                page = await zokoRes.json();
                zokoCustomers = zokoCustomers.concat(page.customers);
                ++p;
            } while (p <= page.totalPages);
            // No assignment to Aromal
            let relevantCustomers = zokoCustomers.filter(
                c => c.assignment.id !== "122f419b-c974-4500-bde6-834134c697e7"
            );
            relevantCustomers.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setZokoCustomers(relevantCustomers);
            setZokoApiKey(apiKeys.zokoApiKey);
            setLoading(false);
        };
        fetchData();
    }, []);

    //================================ HANDLERS =================================

    const resetForm = () => {
        setMerchants([]);
        setMerchantSelection([]);
        setCustomerId("");
        setOperation("clientQuote");
        setSubmitDisabled(false);
        setDisplayMerchants(false);
        setFormValidated(false);
        dispatch({ type: "resetForm" });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            setFormValidated(true);
            return;
        }
        setSubmitDisabled(true);
        let payload: MailDataRequired;
        try {
            switch (operation) {
                case "merchantRequest":
                    const mailtoList = merchants.filter(
                        (_, i) => merchantSelection[i]
                    );
                    payload = await createEmailPayload(
                        customer,
                        mailtoList,
                        appState.form.products
                    );
                    await postPayload(
                        `${Config.BACKEND_URL}/sendMerchantsRequests`,
                        payload
                    );
                    break;
                case "clientQuote":
                    const quoteId = customer.quoteId || 1000;
                    await updateDoc(doc(db, "customers", customer.id), {
                        quoteId: quoteId + 1
                    });
                    let firebaseDoc: any = {
                        id: String(quoteId),
                        name: appState.form.name,
                        addressLine1: appState.form.addressLine1,
                        city: appState.form.city,
                        postalCode: appState.form.postalCode,
                        email: appState.form.email,
                        mainDescription: appState.form.mainDescription,
                        optionalDescription: appState.form.optionalDescription
                    };
                    if (appState.form.mainPrice) {
                        firebaseDoc.mainPrice = Number(appState.form.mainPrice);
                    }
                    if (appState.form.optionalPrice) {
                        firebaseDoc.optionalPrice = Number(
                            appState.form.optionalPrice
                        );
                    }
                    try {
                        await setDoc(
                            doc(
                                db,
                                "customers",
                                customer.id,
                                "quotes",
                                String(quoteId)
                            ),
                            firebaseDoc
                        );
                    } catch (e) {
                        const err = e as Error;
                        console.error(err);
                        displayError(dispatch, err.message);
                        setSubmitDisabled(false);
                        return;
                    }
                    const zokoOpts = {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                            accept: "application/json",
                            apikey: zokoApiKey
                        },
                        body: JSON.stringify({
                            channel: "whatsapp",
                            recipient: customer.phone,
                            type: "template",
                            templateId: "cologic_procurement_tool_estimate",
                            templateArgs: [
                                `${Config.QUOTES_URL}/customers/${customer.id}/quotes/${quoteId}`
                            ]
                        })
                    };
                    const zokoRes = await fetch(
                        `${Config.ZOKO_URL}/message`,
                        zokoOpts
                    );
                    if (!zokoRes.ok) {
                        const zokoBody = await zokoRes.json();
                        throw new Error(zokoBody);
                    }
                    break;
                default:
                    throw new Error(`Unknown operation selected: ${operation}`);
            }
            displaySuccess(dispatch);
        } catch (e) {
            displayError(dispatch, JSON.stringify(e));
            console.error(e);
        }
        resetForm();
    };

    const handleMerchantsTick = (index: number): void => {
        let newSel: boolean[] = [...merchantSelection];
        newSel[index] = newSel[index] !== true;
        setMerchantSelection(newSel);
    };

    const handleCustomer = async (id: string): Promise<void> => {
        setCustomerId(id);
        if (id.length === 0) {
            setDisplayMerchants(false);
            return;
        }
        setLoadingCustomerData(true);
        const customerSnap = await getDoc(
            doc(db, "customers", id) as DocumentReference<CologicCustomer>
        );
        if (!customerSnap.exists()) {
            alert(`Add customer to Rowy first: ${id}`);
            setLoadingCustomerData(false);
            setDisplayMerchants(false);
            return;
        }
        const c = customerSnap.data();
        if (c.merchants && c.merchants.length > 0) {
            const merchantsQuerySnap = await getDocs(
                query(
                    collection(db, "merchants"),
                    where(
                        "id",
                        "in",
                        c.merchants.map(m => m.id)
                    )
                ) as Query<Merchant>
            );
            if (!merchantsQuerySnap.empty) {
                setMerchants(merchantsQuerySnap.docs.map(snap => snap.data()));
                setMerchantSelection(Array.from(c.merchants, () => false));
            }
        } else {
            setMerchants([]);
            setMerchantSelection([]);
        }
        setLoadingCustomerData(false);
        setDisplayMerchants(true);
        setCustomer(c);
    };

    //================================ RENDERING ================================

    const header = (
        <Row className="align-items-center my-4">
            <Col sm="8">
                <h6 className="display-6">CoLogic Procurement Tool</h6>
            </Col>
            <Col sm="4" className="text-end">
                <p className="fs-5 m-0">
                    {Config.VERSION}
                </p>
            </Col>
        </Row>
    );

    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <Spinner
                    animation="border"
                    variant="secondary"
                    style={{ margin: "0 20px" }}
                />
                <h6 className="display-6 d-inline">Loading...</h6>
            </div>
        );
    }

    return (
        <Container>
            <Row>
                <Col sm="12" xxl={{ span: 9, offset: 1 }}>
                    {header}
                    <p className="fw-light fst-italic mb-1">
                        Use this tool as often as Monday, Tuesday, Wednesday ...
                    </p>
                    <p className="fw-light fst-italic">
                        ... to be 25 million percent more effective!
                    </p>

                    <Row>
                        <Col sm="12" md="4" className="mb-3 mb-md-0">
                            <ButtonGroup className="w-100 shadow-sm">
                                <Button
                                    variant="outline-secondary"
                                    active={operation === "clientQuote"}
                                    onClick={() => setOperation("clientQuote")}
                                >
                                    Client Quote
                                </Button>
                                <Button
                                    variant="outline-secondary"
                                    active={operation === "merchantRequest"}
                                    onClick={() =>
                                        setOperation("merchantRequest")
                                    }
                                >
                                    Merchant Request
                                </Button>
                            </ButtonGroup>
                        </Col>

                        <Col sm="12" md="8">
                            <FloatingLabel
                                controlId="form-customer"
                                label="Customer"
                            >
                                <Form.Select
                                    className="shadow-sm"
                                    value={customerId}
                                    onChange={e =>
                                        handleCustomer(e.target.value)
                                    }
                                    required
                                    form="form-create"
                                >
                                    <option value="" />
                                    {zokoCustomers.map(details => (
                                        <option
                                            key={details.id}
                                            value={details.id}
                                        >
                                            {details.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Customer required
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    {operation === "clientQuote" && (
                        <QuoteForm
                            formId="form-create"
                            handleSubmit={handleSubmit}
                            validated={formValidated}
                        />
                    )}
                    {operation === "merchantRequest" && (
                        <Products
                            data={appState.form.products}
                            dispatch={dispatch}
                        />
                    )}
                    <Merchants
                        hidden={
                            operation !== "merchantRequest" ||
                            !displayMerchants ||
                            loadingCustomerData
                        }
                        data={merchants}
                        checkedList={merchantSelection}
                        onChange={handleMerchantsTick}
                    />
                    <Button
                        className="shadow-sm mb-4"
                        form="form-create"
                        disabled={submitDisabled || loadingCustomerData}
                        type="submit"
                    >
                        {(!submitDisabled && "Send!") || (
                            <div>
                                <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                />
                                <p
                                    style={{ margin: "0 0 0 1em" }}
                                    className="d-inline"
                                >
                                    Sending...
                                </p>
                            </div>
                        )}
                    </Button>
                    {loadingCustomerData && (
                        <p className="mb-0 ml-3 d-inline">
                            Loading customer data...
                        </p>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default QuoteCreate;
