import { FormEvent, useContext, useEffect, useState } from "react";
import { json, useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import {
    doc,
    getDoc,
    setDoc,
    updateDoc,
    DocumentReference,
    Timestamp
} from "firebase/firestore";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import {
    Config,
    displayError,
    displaySuccess,
    createEmailPayload,
    db,
    postPayload
} from "./utils";
import { CologicCustomer, Quote } from "./RootComponent.d";
import { QuoteEditParams } from "./QuoteEdit.d";
import QuoteForm from "./QuoteForm";
import { AppStateContext } from "./RootComponent";

async function loader(args: LoaderFunctionArgs) {
    const params = args.params as QuoteEditParams;
    const quoteSnap = await getDoc(
        doc(
            db,
            "customers",
            params.customerId,
            "quotes",
            params.quoteId
        ) as DocumentReference<Quote>
    );
    const customerSnap = await getDoc(
        doc(
            db,
            "customers",
            params.customerId
        ) as DocumentReference<CologicCustomer>
    );
    if (!quoteSnap.exists() || !customerSnap.exists()) {
        throw json(
            {
                message:
                    `No such customer "${params.customerId}"` +
                    ` or quote "${params.quoteId}".`
            },
            {
                status: 404,
                statusText: "Not Found"
            }
        );
    }
    return {
        quote: quoteSnap.data(),
        customer: customerSnap.data()
    };
}

function QuoteEdit() {
    const { appState, dispatch } = useContext(AppStateContext);
    const data = useLoaderData() as { quote: Quote; customer: CologicCustomer };

    const [formValidated, setFormValidated] = useState(false);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [saving, setSaving] = useState(false);
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);

    useEffect(() => {
        const writeTimestamp = async () => {
            const openedAt = new Timestamp(Math.floor(Date.now() / 1000), 0);
            await updateDoc(
                doc(db, "customers", data.customer.id, "quotes", data.quote.id),
                {
                    openedAt
                }
            );
        };
        writeTimestamp();

        dispatch({
            type: "replaceFormState",
            value: {
                name: data.quote.name || "",
                addressLine1: data.quote.addressLine1 || "",
                city: data.quote.city || "",
                postalCode: data.quote.postalCode || "",
                email: data.quote.email || "",
                phone: data.quote.phone || "",
                mainPrice: String(data.quote.mainPrice || ""),
                mainDescription: data.quote.mainDescription || "",
                optionalPrice: String(data.quote.optionalPrice || ""),
                optionalDescription: data.quote.optionalDescription || "",
                products: data.quote.products || []
            }
        });
    }, [data, dispatch]);

    const saveData = async () => {
        let firebaseDoc: any = {
            id: data.quote.id,
            name: appState.form.name,
            addressLine1: appState.form.addressLine1,
            city: appState.form.city,
            postalCode: appState.form.postalCode,
            email: appState.form.email,
            mainDescription: appState.form.mainDescription,
            optionalDescription: appState.form.optionalDescription
        };
        if (appState.form.mainPrice) {
            firebaseDoc.mainPrice = Number(appState.form.mainPrice);
        }
        if (appState.form.optionalPrice) {
            firebaseDoc.optionalPrice = Number(appState.form.optionalPrice);
        }

        try {
            await setDoc(
                doc(db, "customers", data.customer.id, "quotes", data.quote.id),
                firebaseDoc
            );
        } catch (e) {
            const err = e as Error;
            console.error(err);
            displayError(dispatch, err.message);
            return;
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            setFormValidated(true);
            return;
        }
        setDisabledButtons(true);
        setSaving(true);
        await saveData();
        displaySuccess(dispatch);
        setDisabledButtons(false);
        setSaving(false);
    };

    const handleSendToClientOrPdf = async (endpoint: string) => {
        if (endpoint === "sendingEmail" && appState.form.email.length === 0) {
            displayError(
                dispatch,
                "Please provide a valid client email address!"
            );
            return;
        }
        await saveData();
        let products = [
            {
                description: appState.form.mainDescription,
                price: appState.form.mainPrice,
                quantity: "1"
            }, {
                description: appState.form.optionalDescription,
                price: appState.form.optionalPrice,
                quantity: "1"
            }
        ];
        const payload = await createEmailPayload(
            data.customer,
            {
                name: appState.form.name,
                addressLine1: appState.form.addressLine1,
                city: appState.form.city,
                postalCode: appState.form.postalCode,
                email: appState.form.email,
                phone: appState.form.phone
            },
            products,
            data.quote.id
        );
        let resData: Blob;
        try {
            resData = await postPayload(
                `${Config.BACKEND_URL}/${endpoint}`,
                payload
            );
        } catch (e) {
            const err = e as Error;
            displayError(dispatch, err.message);
            return;
        }
        dispatch({ type: "setAndShowSuccess" });
        return resData;
    };

    const handleSendToClient = async () => {
        setDisabledButtons(true);
        setSendingEmail(true);
        await handleSendToClientOrPdf("sendClientQuote");
        setDisabledButtons(false);
        setSendingEmail(false);
    };

    const handleGeneratePdf = async () => {
        setDisabledButtons(true);
        setGeneratingPdf(true);
        const pdfBlob = await handleSendToClientOrPdf("createPdf");
        if (!pdfBlob) {
            const errMsg = "Empty PDF!";
            console.error(errMsg);
            displayError(dispatch, errMsg);
            setDisabledButtons(false);
            setGeneratingPdf(false);
            return;
        }
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_self");
        setDisabledButtons(false);
        setGeneratingPdf(false);
    };

    return (
        <Container>
            <Row className="align-items-center my-4">
                <Col sm="8">
                    <h1 className="text-body my-4 display-5">
                        Quote no. {data.quote.id}
                    </h1>
                </Col>
            </Row>
            <QuoteForm
                formId="form-edit"
                handleSubmit={handleSubmit}
                validated={formValidated}
            />
            <Row className="mb-4" xs="12">
                <Col xs="12" className="mb-3">
                    <Button
                        form="form-edit"
                        className="w-100 shadow-sm"
                        variant="primary"
                        type="submit"
                        disabled={disabledButtons}
                    >
                        {(!saving && "Save Changes") || (
                            <div>
                                <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                />
                                <p
                                    style={{ margin: "0 0 0 1em" }}
                                    className="d-inline"
                                >
                                    Saving...
                                </p>
                            </div>
                        )}
                    </Button>
                </Col>
                <Col xs="6" className="mb-3">
                    <Button
                        className="w-100 shadow-sm"
                        variant="secondary"
                        type="button"
                        disabled={disabledButtons}
                        onClick={handleGeneratePdf}
                    >
                        {(!generatingPdf && "View PDF") || (
                            <div>
                                <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                />
                                <p
                                    style={{ margin: "0 0 0 1em" }}
                                    className="d-inline"
                                >
                                    Creating PDF...
                                </p>
                            </div>
                        )}
                    </Button>
                </Col>
                <Col xs="6" className="mb-3">
                    <Button
                        className="w-100 shadow-sm"
                        variant="secondary"
                        type="button"
                        onClick={handleSendToClient}
                        disabled={disabledButtons}
                    >
                        {(!sendingEmail && "Send Email to Client") || (
                            <div>
                                <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                />
                                <p
                                    style={{ margin: "0 0 0 1em" }}
                                    className="d-inline"
                                >
                                    Sending...
                                </p>
                            </div>
                        )}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export { QuoteEdit, loader };
