import { FormEvent, useContext, useRef, useState } from "react";
import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useMediaQuery } from "react-responsive";
import TagManager from "react-gtm-module";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

import { DesktopProductsView, MobileProductsView } from "./product-views";
import { displayError, Config } from "./utils";
import { SearchProduct, SearchMode } from "./RootComponent.d";
import { AppStateContext } from "./RootComponent";
import firebaseConfig from "./firebaseProdConfig";
import ProductsCarousel from "./ProductsCarousel";

const NUM_SOURCES = 25;
const MAPS_LIBRARIES = ["places"] as ["places"];

function loader(args: LoaderFunctionArgs) {
    return {
        searchParams: new URL(args.request.url).searchParams
    };
}

function ProductSearch() {
    const { dispatch } = useContext(AppStateContext);
    const loaderData = useLoaderData() as { searchParams: URLSearchParams };
    const numDisplay = loaderData.searchParams.get("display");
    const enableLocation = loaderData.searchParams.get("enable_location");
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: firebaseConfig.apiKey,
        libraries: MAPS_LIBRARIES
    });

    const [searchString, setSearchString] = useState("");
    const [products, setProducts] = useState<SearchProduct[]>([]);
    const [postalCode, setPostalCode] = useState("");
    const autocompleteRef = useRef<google.maps.places.Autocomplete>();

    const [formValidated, setFormValidated] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [numFetched, setNumFetched] = useState(-1);
    const [searchMode, setSearchMode] = useState<SearchMode>("collection");

    const isDesktop = useMediaQuery({ minWidth: 768 }); // Bootstrap medium size

    if (loadError) {
        console.error("Can't load Google Maps API");
    }

    if (!isLoaded) {
        return <p>Loading location provider</p>;
    }

    const handlePlaceSelected = () => {
        if (!autocompleteRef.current) {
            console.error("No 'current' field in autocompleteRef");
            return;
        }
        const place = autocompleteRef.current.getPlace();
        if (!place.address_components) {
            console.log(place.name);
            return;
        }
        const pcField = place.address_components.find(c =>
            c.types.includes("postal_code")
        );
        if (pcField) {
            setPostalCode(pcField.long_name);
        }
    };

    const handleCurrentLocation = () =>
        navigator.geolocation.getCurrentPosition(
            async geoLocPos => {
                const { latitude, longitude } = geoLocPos.coords;
                const geocoder = new google.maps.Geocoder();
                const geoRes = await geocoder.geocode({
                    location: { lat: latitude, lng: longitude }
                });
                const ac = geoRes.results[0].address_components.find(addrComp =>
                    addrComp.types.includes("postal_code")
                );
                if (!ac) {
                    setPostalCode(geoRes.results[0].formatted_address);
                } else {
                    setPostalCode(ac.long_name);
                }
            },
            err =>
                displayError(dispatch, `Can't load geolocation: ${err.message}`)
        );

    const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            setFormValidated(true);
            return;
        }
        TagManager.dataLayer({
            dataLayer: {
                event: "search",
                search_term: searchString
            }
        });
        setProducts([]);
        setNumFetched(0);
        setSubmitDisabled(true);
        const queryParams = new URLSearchParams([["search", searchString]]);
        const queryString = queryParams.toString();
        await Promise.all(
            [...new Array(NUM_SOURCES)].map(async (_, i) => {
                let prods = [] as SearchProduct[];
                let jsonText: string;
                return fetch(
                    `${
                        Config.BACKEND_URL
                    }/product-search/${i}?${queryString.toString()}`
                )
                    .then(async res => {
                        if (!res.ok) {
                            console.error(
                                `Response ${res.status}, ${res.statusText}`
                            );
                        }
                        jsonText = await res.text();
                        if (jsonText.length === 0) {
                            return;
                        }
                        prods = JSON.parse(jsonText);
                        setProducts(oldProds => {
                            const newProds = oldProds.concat(prods);
                            newProds.sort((p, q) => q.relevance - p.relevance);
                            return numDisplay
                                ? newProds.slice(0, Number(numDisplay))
                                : newProds;
                        });
                    })
                    .catch(e => {
                        const err = e as Error;
                        displayError(dispatch, err.message);
                        console.error(err.message);
                        console.error(`Received JSON ${jsonText}`);
                        setSubmitDisabled(false);
                    })
                    .finally(() => {
                        setNumFetched(n => n + 1);
                    });
            })
        );
        setTimeout(() => setNumFetched(-1), 1000);
        setSubmitDisabled(false);
    };

    return (
        <Container
            className="mt-4"
            style={{ fontFamily: "'Rubik', Sans-serif" }}
        >
            <Row className="mb-4">
                <Col xs="12">
                    <p
                        style={{ color: "#47C9FF", fontFamily: "Jockey One" }}
                        className="display-4 text-center"
                    >
                        Trade Parts Finder
                    </p>
                </Col>
                <Col xs="12">
                    <ProductsCarousel />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="12">
                    <ButtonGroup className="w-100 shadow-sm">
                        <Button
                            variant="outline-secondary"
                            active={searchMode === "collection"}
                            onClick={() => setSearchMode("collection")}
                        >
                            Collection
                        </Button>
                        <Button
                            variant="outline-secondary"
                            active={searchMode === "delivery"}
                            onClick={() => setSearchMode("delivery")}
                        >
                            Delivery
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Form
                noValidate
                onSubmit={handleSearch}
                validated={formValidated}
                className="mb-3"
            >
                <Form.Group
                    as={Row}
                    controlId="form-search"
                    className="mb-4 mb-md-3"
                >
                    {searchMode === "collection" && (
                        <>
                            <Col xs="6" sm="7" md="9" className="mb-3">
                                <Autocomplete
                                    onLoad={ac =>
                                        (autocompleteRef.current = ac)
                                    }
                                    onPlaceChanged={handlePlaceSelected}
                                    options={{
                                        fields: ["address_components"],
                                        bounds: {
                                            north: 51.54545449423976,
                                            south: 51.47857785677586,
                                            east: -0.005455931042209942,
                                            west: -0.22490814988888397
                                        },
                                        strictBounds: true,
                                        componentRestrictions: { country: "gb" }
                                    }}
                                >
                                    <Form.Control
                                        placeholder="My location ..."
                                        onChange={e =>
                                            setPostalCode(e.target.value)
                                        }
                                        value={postalCode}
                                        required
                                        autoFocus
                                        className="shadow-sm rounded-5 px-3"
                                    />
                                </Autocomplete>
                                <Form.Control.Feedback type="invalid">
                                    Location required
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs="6" sm="5" md="3">
                                <button
                                    type="button"
                                    onClick={handleCurrentLocation}
                                    className={
                                        "bg-secondary w-100 shadow-sm " +
                                        "rounded-5 border-0 py-2 text-white"
                                    }
                                >
                                    Current Location
                                </button>
                            </Col>
                        </>
                    )}

                    <Col xs="12" sm="9" md="10" className="mb-3 mb-sm-0">
                        <Form.Control
                            placeholder="Vaillant ecoTEC Pro 28 ..."
                            value={searchString}
                            onChange={e => setSearchString(e.target.value)}
                            required
                            className="shadow-sm rounded-5 px-3"
                        />
                        <Form.Control.Feedback type="invalid">
                            Search input required
                        </Form.Control.Feedback>
                    </Col>
                    <Col xs="12" sm="3" md="2">
                        <button
                            type="submit"
                            disabled={submitDisabled}
                            style={{
                                backgroundColor: "#47C9FF"
                            }}
                            className={
                                "w-100 shadow-sm rounded-5 border-0 " +
                                "py-2 text-white"
                            }
                        >
                            {(submitDisabled && (
                                <Spinner
                                    variant="light"
                                    animation="border"
                                    size="sm"
                                />
                            )) ||
                                "Search"}
                        </button>
                    </Col>
                </Form.Group>
            </Form>
            {numFetched >= 0 && (
                <Row className="my-3">
                    <Col xs="12">
                        <ProgressBar
                            animated
                            min={0}
                            max={NUM_SOURCES}
                            now={numFetched}
                            className="rounded-5"
                        />
                    </Col>
                </Row>
            )}
            {(isDesktop && (
                <DesktopProductsView
                    products={products}
                    searchMode={searchMode}
                />
            )) || (
                <MobileProductsView
                    products={products}
                    enableLocation={enableLocation === "true"}
                    searchMode={searchMode}
                />
            )}
            <Row className="mt-5">
                <Col xs="12" className="text-center fs-5">
                    <p style={{ fontFamily: "Jockey One" }}>
                        All prices are excluding VAT
                    </p>
                    <p style={{ fontFamily: "Roboto" }} className="fs-6 mb-5">
                        Use the Trade Parts Finder search tool to help you find
                        the best prices on trade parts from over 30 different
                        websites.
                    </p>
                    <p
                        style={{ fontFamily: "Roboto" }}
                        className="fs-6 text-end"
                    >
                        All rights reserved
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export { loader, ProductSearch };
