import { InputLineProps, ProductsProps } from "./Products.d";

function InputLine(props: InputLineProps): JSX.Element {
    return (
        <>
            <input
                type="number"
                required
                autoFocus
                value={props.data.quantity}
                onChange={e => props.dispatch({
                    type: "writeProduct",
                    index: props.index,
                    field: "quantity",
                    value: e.target.value
                })}
            />
            <input
                type="text"
                required
                value={props.data.name}
                onChange={e => props.dispatch({
                    type: "writeProduct",
                    index: props.index,
                    field: "name",
                    value: e.target.value
                })}
            />
            <input
                type="text"
                value={props.data.description}
                onChange={e => props.dispatch({
                    type: "writeProduct",
                    index: props.index,
                    field: "description",
                    value: e.target.value
                })}
            />
            <input
                type="number"
                value={props.data.price}
                onChange={e => props.dispatch({
                    type: "write",
                    index: props.index,
                    field: "price",
                    value: e.target.value
                })}
            />
        </>
    );
}

/**
 * Provides input fields for the invoice items.
 *
 * Send back an array of objects with the product name, quantity and price to
 * the component above via the onChange call every time the + button was
 * pressed.
 */
export default function Products(props: ProductsProps): JSX.Element {
    return (
        <div className="products-container">
            <div className="products">
                <p>Qty.</p>
                <p>Product</p>
                <p>Description</p>
                <p>Price</p>

                {props.data.map((item, index) =>
                    <InputLine
                        index={index}
                        data={item}
                        key={index}
                        dispatch={props.dispatch}
                    />
                )}
                <div className="plus-minus">
                    <button
                        onClick={() => props.dispatch({ type: "push" })}
                        disabled={
                            props.data[props.data.length - 1]
                                .name.length === 0 ||
                            props.data[props.data.length - 1]
                                .quantity.length === 0
                        }
                        type="button"
                    >
                        +
                    </button>
                    <button
                        onClick={() => props.dispatch({ type: "pop" })}
                        disabled={props.data.length === 1}
                        type="button"
                    >
                        -
                    </button>
                </div>
            </div>
        </div>
    );
}
