import Alert from "react-bootstrap/Alert";
import { useContext } from "react";

import { AlertsProviderProps } from "./providers.d";
import { AppStateContext } from "./RootComponent";

function AlertsProvider(props: AlertsProviderProps) {
    const { appState } = useContext(AppStateContext);
    return (
        <div>
            {props.children}
            {appState.showSuccess && (
                <Alert
                    variant="success"
                    className="mt-4 w-50 position-absolute bottom-0 start-50 translate-middle shadow"
                >
                    {appState.successMsg}
                </Alert>
            )}
            {appState.showError && (
                <Alert
                    variant="danger"
                    className="mt-4 w-50 position-absolute bottom-0 start-50 translate-middle shadow"
                >
                    {appState.errorMsg}
                </Alert>
            )}
        </div>
    );
}

export { AlertsProvider };
