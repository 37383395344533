import { ChangeEvent, useContext } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { QuoteFormProps } from "./QuoteForm.d";
import { AppStateContext } from "./RootComponent";

function QuoteForm(props: QuoteFormProps) {
    const { appState, dispatch } = useContext(AppStateContext);
    return (
        <Form
            id={props.formId}
            noValidate
            validated={props.validated}
            onSubmit={props.handleSubmit}
        >
            <Card className="my-4 shadow-sm">
                <Card.Header>Client Details</Card.Header>
                <Card.Body>
                    {[
                        {
                            id: "client-name",
                            label: "Name",
                            action: (e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: "setFormField",
                                    field: "name",
                                    value: e.target.value
                                }),
                            value: appState.form.name,
                            type: "text",
                            ph: "Full name",
                            fb: "Client name required"
                        },
                        {
                            id: "address-line-1",
                            label: "Address",
                            action: (e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: "setFormField",
                                    field: "addressLine1",
                                    value: e.target.value
                                }),
                            value: appState.form.addressLine1,
                            type: "text",
                            ph: "Address line 1",
                            fb: "Address line required"
                        },
                        {
                            id: "city",
                            label: "City",
                            action: (e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: "setFormField",
                                    field: "city",
                                    value: e.target.value
                                }),
                            value: appState.form.city,
                            type: "text",
                            ph: "City",
                            fb: "City required"
                        },
                        {
                            id: "postal-code",
                            label: "Postal Code",
                            action: (e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: "setFormField",
                                    field: "postalCode",
                                    value: e.target.value
                                }),
                            value: appState.form.postalCode,
                            type: "text",
                            ph: "Postal code",
                            fb: "Postal code required"
                        },
                        {
                            id: "email",
                            label: "Email",
                            action: (e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: "setFormField",
                                    field: "email",
                                    value: e.target.value
                                }),
                            value: appState.form.email,
                            type: "text",
                            ph: "Email address",
                            fb: "Client's email address required"
                        },
                        {
                            id: "phone",
                            label: "Phone",
                            action: (e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: "setFormField",
                                    field: "phone",
                                    value: e.target.value
                                }),
                            value: appState.form.phone,
                            type: "text",
                            ph: "Phone number",
                            fb: "Client's phone number required"
                        }
                    ].map(details => (
                        <Form.Group
                            className="mb-2"
                            controlId={`form-${details.id}`}
                            key={details.id}
                        >
                            <Form.Control
                                onChange={details.action}
                                value={details.value}
                                type={details.type}
                                placeholder={details.ph}
                            />
                            <Form.Control.Feedback type="invalid">
                                {details.fb}
                            </Form.Control.Feedback>
                        </Form.Group>
                    ))}
                </Card.Body>
            </Card>

            <Card className="mb-4 shadow-sm">
                <Card.Header>Quotation Details</Card.Header>
                <Card.Body>
                    <Form.Group
                        as={Row}
                        xs="12"
                        className="mb-2"
                        controlId="form-main-price"
                    >
                        <InputGroup>
                            <InputGroup.Text>£</InputGroup.Text>
                            <Form.Control
                                onChange={e =>
                                    dispatch({
                                        type: "setFormField",
                                        field: "mainPrice",
                                        value: e.target.value
                                    })
                                }
                                required
                                value={appState.form.mainPrice}
                                type="number"
                                placeholder="Price"
                            />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            Main price required
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="mb-2"
                        controlId="form-main-description"
                    >
                        <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Works description, labour, materials..."
                            onChange={e =>
                                dispatch({
                                    type: "setFormField",
                                    field: "mainDescription",
                                    value: e.target.value
                                })
                            }
                            value={appState.form.mainDescription}
                            style={{ height: "100px" }}
                        />
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        xs="12"
                        className="my-2"
                        controlId="form-optional-price"
                    >
                        <InputGroup>
                            <InputGroup.Text>£</InputGroup.Text>
                            <Form.Control
                                onChange={e =>
                                    dispatch({
                                        type: "setFormField",
                                        field: "optionalPrice",
                                        value: e.target.value
                                    })
                                }
                                value={appState.form.optionalPrice}
                                type="number"
                                placeholder="(Optional) Other price"
                            />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            Other price required
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="mb-2"
                        controlId="form-optional-description"
                    >
                        <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="(Optional) Other information, items, works, notes..."
                            onChange={e =>
                                dispatch({
                                    type: "setFormField",
                                    field: "optionalDescription",
                                    value: e.target.value
                                })
                            }
                            value={appState.form.optionalDescription}
                            style={{ height: "100px" }}
                        />
                    </Form.Group>
                </Card.Body>
            </Card>
        </Form>
    );
}

export default QuoteForm;
