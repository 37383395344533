import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { SearchProduct, SearchMode } from "./RootComponent.d";

interface DesktopProductsViewProps {
    products: SearchProduct[];
    searchMode: SearchMode;
}

interface MobileProductsViewProps {
    products: SearchProduct[];
    enableLocation: boolean;
    searchMode: SearchMode;
}

function DesktopProductsView(props: DesktopProductsViewProps) {
    return (
        <Row>
            <Col xs="12">
                {props.products.length > 0 && (
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>In Stock</th>
                                <th>
                                    {props.searchMode === "collection"
                                        ? "Distance"
                                        : "Delivery Time"}
                                </th>
                                <th>Price</th>
                                <th>Merchant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.products.map((p, i) => (
                                <tr key={i}>
                                    <td className="d-flex">
                                        <img
                                            width="100px"
                                            src={p.imgUrl}
                                            alt="Unavailable"
                                            className="mx-auto"
                                        />
                                    </td>
                                    <td>{p.name}</td>
                                    <td>
                                        {props.searchMode === "collection"
                                            ? p.pickupStock
                                            : p.deliveryStock}
                                    </td>
                                    <td>
                                        {props.searchMode === "collection"
                                            ? p.pickupDistance
                                            : p.deliveryTime}
                                    </td>
                                    <td>£{p.price}</td>
                                    <td>
                                        <a
                                            href={p.link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {p.merchant}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Col>
        </Row>
    );
}

function MobileProductsView(props: MobileProductsViewProps) {
    return (
        <Row>
            <Col xs="12">
                {props.products.length > 0 &&
                    props.products.map((p, i) => (
                        <Card key={i} className="mb-2">
                            <Row>
                                <Col
                                    xs="3"
                                    className="d-flex align-items-center pe-0"
                                >
                                    <Card.Img src={p.imgUrl} />
                                </Col>
                                <Col xs="9" className="ps-0">
                                    <Card.Body>
                                        <Row className="mb-3">
                                            <Col xs="12">{p.name}</Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs="6">
                                                <Row>
                                                    <Col
                                                        xs="12"
                                                        style={{
                                                            fontSize: "12px"
                                                        }}
                                                    >
                                                        In Stock
                                                    </Col>
                                                    <Col xs="12">
                                                        {props.searchMode ===
                                                        "collection"
                                                            ? p.pickupStock
                                                            : p.deliveryStock}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="6">
                                                <Row>
                                                    <Col
                                                        xs="12"
                                                        style={{
                                                            fontSize: "12px"
                                                        }}
                                                    >
                                                        {props.searchMode ===
                                                        "collection"
                                                            ? "Distance"
                                                            : "Delivery Time"}
                                                    </Col>
                                                    <Col xs="12">
                                                        {props.searchMode ===
                                                        "collection"
                                                            ? p.pickupDistance
                                                            : p.deliveryTime}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs="6" className="pe-0">
                                                <a
                                                    href={p.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="stretched-link"
                                                >
                                                    {p.merchant}
                                                </a>
                                            </Col>
                                            <Col
                                                xs="6"
                                                className="text-end fw-bold ps-0 fs-5"
                                            >
                                                £{p.price}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    ))}
            </Col>
        </Row>
    );
}

export { DesktopProductsView, MobileProductsView };
